<template>
  <div class="item">
    <el-col :span="6">
      <div :class="goodsDetails.goodsType == 8?'leftContent marTbox':'leftContent'"   @click="jumpDetail(goodsDetails)">
        <img :src="goodsDetails.goodsPic.split(',')[0]" alt="" />
      </div>
      <div class="rightContent">
        <el-col :span="24" class="nameTit">
          <span class="nameBox" :title="goodsDetails.goodsName">{{ goodsDetails.goodsName }}</span>
          <!-- 0. 普通 1. 赠品 3. 活动 4.A+B [畅享易购(A)] 5.A+B [畅享易购(B)]） -->
          <span class="gift">{{
            goodsDetails.goodsType == 0
              ? "[普通商品]"
              : goodsDetails.goodsType == 1
              ? "[赠品]"
              : goodsDetails.goodsType == 2 || goodsDetails.goodsType == 11
              ? "[奖品]"
              : goodsDetails.goodsType == 3
              ? "[活动商品]"
              :  goodsDetails.goodsType == 4
              ? ""
              :  goodsDetails.goodsType == 5
              ? ""
              :  goodsDetails.goodsType == 6
              ? "[套餐]"
              : ""
          }}</span>
              <!-- :  goodsDetails.goodsType == 8
              ? "[预售]"
              : "" -->
        </el-col>
        <el-col :span="24" class="specMsg" v-if="goodsDetails.specification!=''">
          <!--  {{ goodsDetails.partSpecification }} -->
          <span>药品规格:</span>{{ goodsDetails.specification }}
         </el-col
        >
         <el-col :span="24" class="dateMsg" v-if="goodsDetails.partSpecification!=''">
          <span>件装规格:</span>{{ goodsDetails.partSpecification }}</el-col
        >
         <!-- <div  :span="24" class="dateMsg" v-if="goodsDetails.manufacturerName!=''">
         厂家:{{goodsDetails.manufacturerName}}
      </div> -->
        <el-col :span="24" class="dateMsg" v-if="goodsDetails.validDate!=''">
          <span>效期优于:</span>{{ goodsDetails.validDate }}</el-col
        >
        <el-col  v-if="proSec!=''">
          <span>{{proSec}}</span>
        </el-col>
      </div>
    </el-col>
    <el-col :span="2" class="text-center goodsBox">
      ￥{{ goodsDetails.originalPrice }}
      </el-col>
    <el-col :span="2" class="text-center goodsBox">{{
      goodsDetails.goodsCount
    }}</el-col>
    <el-col
      :span="5"
      class="text-center goodsBox"
      :key="goodsDetails.couponList.productId"
    >
    <span>{{  Number(goodsDetails.couponAmount).toFixed(2)  }}  </span>
    <el-select
        v-if="goodsDetails.couponList.length>0"
        v-model="couponSelected"
        placeholder="请选择"
        clearable
        @change="goodsCouponFun"
      >
        <el-option
          v-for="(item, index) in goodsDetails.couponList"
          :key="index"
          :label="item.couponName"
          :value="{
            couponId: item.couponId,
            label: item.couponName,
            amount: item.exemptionAmount,
            soleId:indexNum,
            superimposeStatus:item.superimposeStatus
          }"
        >
        </el-option>
      </el-select>

      <!-- <el-select
        v-model="couponSelected"
        placeholder="请选择"
        clearable
        @change="goodsCouponFun"
      >

        <el-option
          v-for="(item, index) in goodsDetails.couponList.couponItemVOList"
          :key="index"
          :label="item.couponName"
          :value="{
            couponId: item.couponId,
            label: item.couponName,
            amount: item.amount,
            soleId:indexNum
          }"
        >
        </el-option>
      </el-select> -->
    </el-col>
    <el-col :span="5" class="text-center orderPromotionsBox">
      <div class="orderPromotionsMsg" v-if="goodsDetails.orderPromotions.length>0">
        <span
          v-for="(item, index) in goodsDetails.orderPromotions"
          :key="index"
        >{{ item.name }}
          <b v-if="index != goodsDetails.orderPromotions.length - 1">,</b>
        </span>
      </div>
      <div class="orderPromotionsMsg" v-else style="text-align:center;text-indent:0px">
          无
      </div>
    </el-col>
    <el-col :span="2" class="text-center goodsBox" v-if='goodsDetails.promotionAmount>0'> <span >-{{goodsDetails.promotionAmount}}</span></el-col>
    <el-col :span="2" class="text-center goodsBox" v-else>0</el-col>
     <el-col :span="2" class="text-right goodsBox">
      ￥{{ Number(goodsDetails.goodsCount*goodsDetails.originalPrice).toFixed(2) }} 
      <!-- ￥{{ goodsDetails.payablePrice }} -->
    </el-col>
    
  </div>
</template>

<script>
export default {
  name: "GoodsItem",
  data() {
    return {
      indexNum:'',
      couponSelected: "",
      couponAmount:0,
      proSec:'',
    };
  },
  props: {
    goodsDetails: {
      type: Object,
    },
    num:{
      type:Number
    },
    allowCoupon:{
      type:Number
    }
  },
  created() {
    // console.log(this.goodsDetails.goodsName,this.goodsDetails)
    this.indexNum=this.num
    let couponList=this.goodsDetails.couponList;
    if(couponList){
      for(let i=0;i<couponList.length;i++){
        if(couponList[i].isChecked){
          this.couponSelected = couponList[i].couponName;
          // alert('0:不可叠加,1:可叠加  #'+couponList[i].superimposeStatus)
          if(couponList[i].superimposeStatus==0){
            // 当前选中优惠券为不可叠加，其余优惠券列表中优惠券全部置灰
            localStorage.setItem("AllStatus", 0);
          }
        }
      }
    }
  },
  computed: {
  
  },
  methods: {
    jumpDetail(goods) {
      if(this.goodsDetails.goodsType===4){
        // 跳a+b
         this.$router.push({
          name: "ComboDetail",
          query: { productId: goods.goodsId, promotionId: goods.promotionId },
        });
        
      } else if(this.goodsDetails.goodsType===6){
        // 跳套餐详情页
           this.$router.push({
              name: "SetMealDetail",
              query: { promotionId: goods.goodsId},
            });
        
      }else{
       this.$router.push({ name: "ProductDetail", query: { id: goods.goodsId ,isRecentExpiration:goods.isRecentExpiration } });
      }
    },
    goodsCouponFun(params) {
       console.log(params)
      console.log('判断是否为不可用优惠券商品0不可用',this.allowCoupon)
        // 判断是否为不可用优惠券商品
        if(this.allowCoupon==0){  //0不可用
            this.couponSelected=""
            this.$message({
                  message:'该订单不可使用优惠券',
                  type: "warning",
                });
          params={soleId:this.indexNum, amount:0,}
        }else{
              if(params==''){

                console.log(this.goodsDetails)
                  params={soleId:this.indexNum, amount:0,superimposeStatus:this.goodsDetails.superimposeStatus}
                  // let couponList=this.goodsDetails.couponList;
                  // for(let i=0;i<couponList.length;i++){
                  //     if(couponList[i].couponId==soleId){
                  //       this.couponSelected = couponList[i].couponName;
                  //     }
                  // }
                  console.log('（当前优惠券0为不可叠加,1为可叠加）'+this.goodsDetails.superimposeStatus)
              }else{
                // alert(localStorage.getItem('specCouponAmount'))
                if(Number(localStorage.getItem('specCouponAmount'))>0){
                  // 有选中的多品券，则单品券不可用
                          this.couponSelected=""
                          this.$message({
                            message:'不能和当前已选优惠券叠加使用',
                            type: "warning",
                          });
                          return false
                }else{
                  // 如果有选中的通用券，则不可叠加的单品券不可点击叠加使用
                  // alert(localStorage.getItem('couponAmountNum'))
                  if(localStorage.getItem('couponAmountNum')>0){
                    // alert(params.superimposeStatus==0?'不可叠加':'可叠加')
                    // alert('如果有选中的通用券，则不可叠加的单品券不可点击叠加使用')
                    if(params.superimposeStatus==0){
                      this.couponSelected=""
                          this.$message({
                            message:'不能和当前已选优惠券叠加使用',
                            type: "warning",
                          });
                          return false
                    }else{
                      let couponList=this.goodsDetails.couponList;
                      for(let i=0;i<couponList.length;i++){

                          if(couponList[i].couponId==params.couponId){
                            this.couponSelected = couponList[i].couponName;
                          }
                      }
                    }
                  }else{
                    let couponList=this.goodsDetails.couponList;
                      for(let i=0;i<couponList.length;i++){

                          if(couponList[i].couponId==params.couponId){
                            this.couponSelected = couponList[i].couponName;
                          }
                      }   
                  }
                  
                }
              }
              console.log('点击单选优惠券 子传父数据',params)
              this.$emit("GoodsItemFun",params)

             
        }

    },
  },
};
</script>

<style lang="less" scoped>
@import "~style/index.less";
.item {
  margin-top: 4px;
  background: rgba(240, 225, 210, 0.2);
  padding: 10px 20px;
  float: left;
  width: calc(100% - 40px);
  .leftContent {
    float: left;
    width: 80px;
    height: 80px;
    margin-top:10px;
    &.marTbox{
      margin-top: 0;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .rightContent {
    float: left;
    margin-left: 8px;
    width: calc(100% - 88px);
    .nameTit {
      color: #333;
      font-size: 13px;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 8px;
      .nameBox{
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
        display:inline-block;
        max-width:110px;
        font-size: 14px;
      }
      .gift {
        color: #ff9999;
        font-size: 12px;
        vertical-align: super;
      }
    }
    .specMsg {
      font-size: 12px;
      color: #666;
      line-height: 20px;
      margin-bottom: 5px;
      span:first-child {
        margin-right: 5px;
      }
    }
    .dateMsg {
      font-size: 12px;
      color: #666;
      line-height: 20px;
      margin-bottom: 5px;
      span:first-child {
        margin-right: 5px;
      }
    }
  }
  .goodsBox {
    line-height: 80px;
    font-size: 14px;
    color: #666;
  }
  .orderPromotionsBox {
    height: 80px;
    justify-content: center; //子元素水平居中
    align-items: center; //子元素垂直居中
    display: -webkit-flex;
    .orderPromotionsMsg {
      font-size: 14px;
      color: #666;
      text-align: left;
      text-indent: 20px;
    }
  }

  /deep/.el-select {
    height: 30px;
    width: 175px;
    line-height: 30px;
    .el-input__inner {
      height: 30px;
      color: #ff3333;
      border-color: #ff3333;
    }
    .el-input__icon {
      line-height: 30px;
      color: #ff3333;
    }
  }
}
</style>
